import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import * as Sentry from "sentry-expo";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toast } from "react-toastify";
import generateGradient from "../functions/generateGradient";

const findCompanyName = () => {
  const path = document.location.pathname;

  let companyName;
  const firstSlash = path.indexOf("/");
  const secondSlash = path.indexOf("/", firstSlash + 1);
  if (secondSlash != -1) {
    companyName = path.substring(firstSlash + 1, secondSlash);
  } else {
    companyName = path.substring(firstSlash + 1);
  }
  return companyName;
};

const onErr = async (err, route) => {
  console.log(`${route} error`);
  console.log(err);
  const userIdentification = await AsyncStorage.getItem("userId");
  Sentry.Browser.configureScope(function (scope) {
    scope.setExtra("function_name", route);
    scope.setExtra("id", userIdentification);
    Sentry.Browser.captureException(err);
  });
  toast.error("Having trouble connecting to the server... Try again in a few.");
};

const calcTabs = (content) => {
  const tabs = [];

  if (content.letter != null && content.letter != "") {
    tabs.push({
      order: 0,
      type: 0,
      title: "Overview",
    });
  }

  for (let i = 0; i < content.length; i++) {
    tabs.push({
      order: tabs.length,
      type: 1, // GOAL
      title: content[i].goalTitle,
      content: content[i].goal,
    });

    const goalOrder = tabs.length - 1;
    let stepOrder = 0;

    const stepArr = content[i].steps;
    for (let ii = 0; ii < stepArr.length; ii++) {
      tabs.push({
        order: tabs.length,
        type: 2, // STEP
        goalOrder: goalOrder,
        stepNumber: stepOrder,
        title: stepArr[ii].stepTitle,
        description: stepArr[ii].stepDescription,
        content: stepArr[ii].step,
      });
      stepOrder++;
    }
  }

  tabs.push({
    order: tabs.length,
    type: 3, // NEXT STEPS
    title: "Next Steps",
    content: content.nextSteps,
  });

  return tabs;
};

const genGradients = (number) => {
  let gradients = [];
  for (let i = 0; i < number; i++) {
    gradients.push(generateGradient());
  }
  return gradients;
};

const planReducer = (state, action) => {
  switch (action.type) {
    case "fetch_plan":
      return {
        ...state,
        plan: action.payload,
        tabs: calcTabs(action.payload.content),
        gradients: genGradients(calcTabs(action.payload.content).length),
      };
    case "select_tab":
      return {
        ...state,
        selectedTab: action.payload,
      };
    case "reset":
      return {
        plan: null,
        selectedTab: 0,
      };
    default:
      return state;
  }
};

const fetchPlan = (dispatch) => async (planId) => {
  try {
    const response = await serverApi.post("/plan", { planId: planId });
    dispatch({ type: "fetch_plan", payload: response.data });
  } catch (err) {
    onErr(err, "fetchPlan");
  }
};

const selectTab = (dispatch) => async (tab) => {
  dispatch({ type: "select_tab", payload: tab });
};

const resetPlanContext = (dispatch) => async () => {
  dispatch({ type: "reset" });
};

const co_name = "SeedFi";

export const { Provider, Context } = createDataContext(
  planReducer,
  {
    fetchPlan,
    selectTab,
    resetPlanContext,
  },
  {
    plan: {
      company: "example-method-id",
      prospectCompany: co_name,
      prospectLogo:
        "https://assets.seedfi.com/assets/static/media/seedfi-logo-black.cb40ad97.svg",
      prospectName: "John",
      letter: "Example letter",
      content: [
        {
          goalTitle: "Pay a User's Debt",
        },
      ],
    },
    tabs: [
      {
        order: 0,
        type: 0,
        title: "Overview",
      },
      {
        order: 1,
        type: 1,
        title: "Pay a User's Debt",
      },
      {
        order: 2,
        type: 2,
        goalOrder: 1,
        stepNumber: 1,
        title: "Create individual (end-user) entity",
        dbTitle: "entities",
        description: `A Method entity represents an individual (person) or corporation (company) that functions as the legal owner of a Method account. This allows us to manage the risk and compliance process for ${co_name}\nIn ${co_name}’s case, you’ll be creating individuals programmatically when an end-user creates an account on ${co_name}. Note: In Production, ${co_name} will only need to provide the name and email address of the end-user to create the entity on Method.`,
        content: {
          type: 1,
          codeSnippet: `curl https://dev.methodfi.com/entities
          -X POST
          -H "Authorization: Bearer sk_SRewVmZa38kx9BWLCjLk3cVK"
          -H "Content-Type: application/json"
          -d '{
            "type": "individual",
            "individual": {
              "first_name": "Kevin",
              "last_name": "Doyle",
              "phone": "+16505555555",
              "email": "kevin.doyle@gmail.com",
              "dob": "1997-03-18"
            }
          }'`,
          requestFields: [
            {
              key: "first_name",
              value: "Kevin",
              type: 0,
              desc: "Legal first name of individual",
            },
            {
              key: "last_name",
              value: "Doyle",
              type: 0,
              desc: "Legal last name of individual",
            },
            {
              key: "phone",
              value: "+16505555555",
              type: 0,
              desc: "Mobile phone number in E.164 format",
            },
            {
              key: "email",
              value: "kevin.doyle@gmail.com",
              type: 0,
              desc: "Email address for KYC/AML",
            },
            {
              key: "dob",
              value: "1997-03-18",
              type: 0,
              desc: "Individual's DOB in ISO 8601 format",
            },
          ],
          botDetails: [
            {
              title: "Creating Entity",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
                {
                  key: "Entity ID",
                  value: "3829382",
                  preloadValue: "localDatabase.entities.id",
                },
              ],
            },
            {
              title: "KYC Verification",
              sub: [
                {
                  key: "Status",
                  value: "Verified",
                },
              ],
            },
            {
              title: "AML Verification",
              sub: [
                {
                  key: "Status",
                  value: "Verified",
                },
              ],
            },
          ],
          successCode: `<View
          style={[
            styles.databaseView,
            { borderTopColor: "#002626", borderTopWidth: 3 },
          ]}
          >
          <View style={{ width: 300, justifyContent: "space-between" }}>
            <View
              style={{
                backgroundColor: "#DEFFF6",
                paddingVertical: 8,
                paddingHorizontal: 15,
                borderRadius: 50,
                position: "absolute",
                top: 0,
                left: 0,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  height: 8,
                  width: 8,
                  borderRadius: 50,
                  backgroundColor: "#002626",
                  marginRight: 6,
                }}
              />
              <Text style={{ color: "#002626" }} small b font="10px">
                Active
              </Text>
            </View>
            <View
              style={{
                alignSelf: "center",
                alignItems: "center",
                marginBottom: 25,
              }}
            >
              <View
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 50,
                  backgroundColor: "#002626",
                  marginBottom: 15,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Ionicons name="person" size={24} color="#00ECAC" />
              </View>
              <Text style={{ marginBottom: -10, textAlign: "center", color: "#002626", fontFamily: "Roobert" }} h4>
                {localDatabase.entities.individual.first_name + " " + localDatabase.entities.individual.last_name}
              </Text>
              <Text style={{ color: "grey", textAlign: "center" }}>
                Entity ID: {localDatabase.entities.id}
              </Text>
            </View>
          
            <Card
              style={{ width: "100%", alignSelf: "center", marginBottom: 25 }}
            >
              <View
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <View>
                  {profileArr.map((elem, index) => (
                    <View
                      key={elem.key}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: index == [1, 2, 3, 4].length ? 0 : 15,
                      }}
                    >
                      <Ionicons
                        name={elem.icon}
                        size={14}
                        color="#002626"
                        style={{ marginRight: 6 }}
                      />
                      <Text style={{ color: "#002626" }} small>
                        {elem.key}
                      </Text>
                    </View>
                  ))}
                </View>
                <View>
                  {profileArr.map((elem, index) => (
                    <View
                      key={elem.value}
                      style={{
                        marginBottom: index == [1, 2, 3, 4].length ? 0 : 15,
                      }}
                    >
                      <Text style={{ textAlign: "end" }} small>{elem.value}</Text>
                    </View>
                  ))}
                </View>
              </View>
            </Card>
          
            <TouchableOpacity
              style={{
                backgroundColor: "#002626",
            width: "100%",
            paddingVertical: 8,
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: 5,
            flexDirection: "row",
            paddingHorizontal: 15,
              }}
            >
            <Ionicons name="ios-checkmark-circle" size={24} color="#00ECAC" />
              <Text style={{ color: "#00ECAC" }} small b>
                Individual Entity Created
              </Text>
              <View />
            </TouchableOpacity>
          </View>
          </View>`,
        },
      },
      {
        order: 3,
        type: 2,
        goalOrder: 1,
        stepNumber: 2,
        title: "Select a merchant",
        dbTitle: "selected_merchant",
        description: `Merchants are financial institutions or lenders that accept payments for any type of liability account. A merchant's mch_id is required to create a liability account. Below are a couple of examples on how to search for a merchant. Method supports over 10k payable merchants with more being added everyday.\nIn ${co_name}’s case, a Method merchant is a lender. If a lender is not found, Method is notified and the lender will be added in a 12 hour timeframe.`,
        content: {
          type: 2,
          data: [
            {
              name: "American Express - Credit Cards",
              parent_name: "American Express",
              types: ["credit_card"],
              mch_id: "mch_3",
              active: "TRUE",
              hidden_api: "FALSE",
              hidden_elem: "FALSE",
              logo: "https://static.methodfi.com/mch-logos/1616215578688-amex.png",
              website: "https://www.americanexpress.com",
              "provider_ids.plaid": ["ins_10"],
              "provider_ids.mx": ["amex"],
            },
            {
              name: "Chase - Credit Cards",
              parent_name: "Chase",
              types: ["credit_card"],
              mch_id: "mch_1",
              active: "TRUE",
              hidden_api: "TRUE",
              hidden_elem: "TRUE",
              logo: "https://static.methodfi.com/mch-logos/1616215578688-chase.png",
              website: "https://creditcards.chase.com",
              "provider_ids.plaid": [],
              "provider_ids.mx": [],
            },
            {
              name: "Bank of America - Loans",
              parent_name: "Bank of America",
              types: ["bank", "credit_card", "mortgage", "student_loan"],
              mch_id: "mch_20",
              active: "TRUE",
              hidden_api: "FALSE",
              hidden_elem: "FALSE",
              logo: "https://static.methodfi.com/mch-logos/1616215578688-bank-of-america.png",
              website: "https://www.bankofamerica.com",
              "provider_ids.plaid": ["ins_127989"],
              "provider_ids.mx": ["bank_of_america"],
            },
            {
              name: "Wells Fargo - Credit Cards",
              parent_name: "Wells Fargo",
              types: ["credit_card"],
              mch_id: "mch_21",
              active: "TRUE",
              hidden_api: "TRUE",
              hidden_elem: "TRUE",
              logo: "https://static.methodfi.com/mch-logos/1616215578688-wells-fargo.png",
              website: "https://creditcards.wellsfargo.com",
              "provider_ids.plaid": [],
              "provider_ids.mx": [],
            },
            {
              name: "Capital One - Credit Cards",
              parent_name: "Capital One",
              types: ["credit_card"],
              mch_id: "mch_22",
              active: "TRUE",
              hidden_api: "FALSE",
              hidden_elem: "FALSE",
              logo: "https://static.methodfi.com/mch-logos/1616215578688-capitalone.png",
              website: "https://www.capitalone.com/credit-cards/",
              "provider_ids.plaid": ["ins_9", "ins_128026"],
              "provider_ids.mx": ["capital_one"],
            },
            {
              name: "FedLoan Servicing - Student Loans",
              parent_name: "FedLoan",
              types: ["student_loan"],
              mch_id: "mch_2",
              active: "TRUE",
              hidden_api: "FALSE",
              hidden_elem: "FALSE",
              logo: "https://static.methodfi.com/mch-logos/1616215578688-fedloan.png",
              website: "https://myfedloan.org/",
              "provider_ids.plaid": ["ins_116527"],
              "provider_ids.mx": ["fedloanservicing"],
            },
            {
              name: "Navient - Student Loans",
              parent_name: "Navient",
              types: ["student_loan"],
              mch_id: "mch_4",
              active: "TRUE",
              hidden_api: "FALSE",
              hidden_elem: "FALSE",
              logo: "https://static.methodfi.com/mch-logos/1616215578688-navient.png",
              website: "https://navient.com/",
              "provider_ids.plaid": ["ins_116248"],
              "provider_ids.mx": ["navientloans"],
            },
            {
              name: "MOHELA SoFi - Student Loans",
              parent_name: "MOHELA",
              types: ["student_loan"],
              mch_id: "mch_5",
              active: "TRUE",
              hidden_api: "FALSE",
              hidden_elem: "FALSE",
              logo: "https://static.methodfi.com/mch-logos/1616215578688-sofi.png",
              website: "https://sofi.mohela.com/",
              "provider_ids.plaid": ["ins_116464"],
              "provider_ids.mx": ["80449"],
            },
            {
              name: "EdFinancial Services - Student Loans",
              parent_name: "EdFinancial",
              types: ["student_loan"],
              mch_id: "mch_8",
              active: "TRUE",
              hidden_api: "FALSE",
              hidden_elem: "FALSE",
              logo: "https://static.methodfi.com/mch-logos/1616215578688-edfinancial.png",
              website: "https://edfinancial.com/",
              "provider_ids.plaid": ["ins_116304"],
              "provider_ids.mx": ["75041"],
            },
            {
              name: "Great Lakes - Student Loans",
              parent_name: "Great Lakes",
              types: ["student_loan"],
              mch_id: "mch_9",
              active: "TRUE",
              hidden_api: "FALSE",
              hidden_elem: "FALSE",
              logo: "https://static.methodfi.com/mch-logos/1616215578688-greatlakes.png",
              website: "https://mygreatlakes.org/",
              "provider_ids.plaid": ["ins_116861"],
              "provider_ids.mx": ["greatlakeseducationalloanservices"],
            },
            {
              name: "Nelnet - Student Loans",
              parent_name: "Nelnet",
              types: ["student_loan"],
              mch_id: "mch_10",
              active: "TRUE",
              hidden_api: "FALSE",
              hidden_elem: "FALSE",
              logo: "https://static.methodfi.com/mch-logos/1616215578688-nelnet.png",
              website: "https://www.nelnet.com/",
              "provider_ids.plaid": ["ins_116528"],
              "provider_ids.mx": ["nelnet"],
            },
            {
              name: "Quicken Loans - Mortgage",
              parent_name: "Quicken Loans",
              types: ["mortgage"],
              mch_id: "mch_32",
              active: "TRUE",
              hidden_api: "FALSE",
              hidden_elem: "FALSE",
              logo: "https://static.methodfi.com/mch-logos/1617403164588-quicken-loans.png",
              website: "https://www.quickenloans.com/",
              "provider_ids.plaid": ["ins_117288"],
              "provider_ids.mx": [
                "70023",
                "6160b82e-b361-493f-a9ee-81e14b59b775",
              ],
            },
            {
              name: "SunTrust - Loans",
              parent_name: "SunTrust",
              types: ["loan", "mortgage", "personal_loan"],
              mch_id: "mch_33",
              active: "TRUE",
              hidden_api: "FALSE",
              hidden_elem: "FALSE",
              logo: "https://static.methodfi.com/mch-logos/1617403164588-suntrust.png",
              website: "https://www.suntrust.com/",
              "provider_ids.plaid": ["ins_16", "ins_107760"],
              "provider_ids.mx": [],
            },
            {
              name: "Best Buy - Credit Cards",
              parent_name: "Best Buy",
              types: ["credit_card"],
              mch_id: "mch_60",
              active: "TRUE",
              hidden_api: "FALSE",
              hidden_elem: "FALSE",
              logo: "https://static.methodfi.com/mch-logos/1618267550019-best-buy.png",
              website: "https://www.bestbuy.com/",
              "provider_ids.plaid": ["ins_116243"],
              "provider_ids.mx": ["69447"],
            },
            {
              name: "Ally - Auto Loans",
              parent_name: "Ally Financial, Inc.",
              types: ["auto_loan"],
              mch_id: "mch_92",
              active: "TRUE",
              hidden_api: "FALSE",
              hidden_elem: "FALSE",
              logo: "https://static.methodfi.com/mch-logos/1622232223591-mch_92.png",
              website: "https://www.ally.com/auto/",
              "provider_ids.plaid": [],
              "provider_ids.mx": ["76795"],
            },
          ],
        },
      },
      {
        order: 4,
        type: 2,
        goalOrder: 1,
        stepNumber: 3,
        title: "Link a Liability for an end-user",
        dbTitle: "accounts",
        description: `Each liability account belongs to a lender, which is also referred to as a merchant. These types of accounts are only able to receive payment funds, and require minimal verification. Like we mentioned before, no OAuth (user credentials) are required: Method only needs a liability account number to initiate a payment.\nMethod validates, in real-time with the lender, the account information to remove the risk of returned or delayed payments. This process only needs to be completed once per a user’s unique debt account.`,
        content: {
          type: 1,
          codeSnippet: `curl https://dev.methodfi.com/accounts
          -X POST
          -H "Authorization: Bearer sk_SRewVmZa38kx9BWLCjLk3cVK"
          -H "Content-Type: application/json"
          -d '{
            "holder_id": "ent_au22b1fbFJbp8",
            "liability": {
              "mch_id": "mch_2",
              "account_number": "1122334455"
            }
          }'`,
          requestFields: [
            {
              key: "holder_id",
              value: "ent_au22b1fbFJbp8",
              preloadKey: "entities.id",
              desc: "User ID",
              preloadDesc: "entities.individual.first_name",
              type: 1,
            },
            {
              key: "mch_id",
              value: "mch_2",
              preloadKey: "selected_merchant.mch_id",
              desc: "Selected Merchant",
              preloadDesc: "selected_merchant.parent_name",
              type: 1,
            },
            {
              key: "account_number",
              value: "1122334455",
              type: 0,
              desc: "Account No. of liability (ex: PAN for credit cards)",
            },
          ],
          botDetails: [
            {
              title: "Identifying Merchant",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
                {
                  key: "Merchant ID",
                  value: "Verified",
                },
              ],
            },
            {
              title: "Verifying with Merchant",
              preloadTitle:
                "`Verifying with ${localDatabase.selected_merchant.parent_name}`",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
            {
              title: "Linking Liability to Entity",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
          ],
          successCode: `
          <View
                  style={[
                    styles.databaseView,
                    { borderTopColor: "#002626", borderTopWidth: 3 },
                  ]}
                >
                  <View style={{ width: 300, justifyContent: "space-between" }}>
                    <View style={{ marginBottom: 25 }}>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "flex-start",
                          marginBottom: -10,
                        }}
                      >
                        <View
                          style={{
                            height: 20,
                            width: 20,
                            borderRadius: 50,
                            backgroundColor: "#002626",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: 10,
                            marginTop: 6,
                          }}
                        >
                          <Ionicons name="person" size={11} color="#00ECAC" />
                        </View>
                        <Text style={{ fontFamily: "Roobert" }} h4>{localDatabase.entities.individual.first_name + " " + localDatabase.entities.individual.last_name}</Text>
                      </View>
                      <Text style={{ color: "grey" }} font="11px">
                        Account ID: {localDatabase.accounts.id}
                      </Text>
                      <View style={{ alignSelf: "flex-start" }}>
                      <Tag
            type="success"
            style={{ backgroundColor: "#002626", color: "#00ECAC", alignSelf: "flex-start" }}
            invert
          >
            Verified with {localDatabase.selected_merchant.parent_name}
          </Tag>
          </View>
                    </View>
          
                    <Card
                      style={{ width: "100%", alignSelf: "center", marginBottom: 25 }}
                    >
                      <View
                        style={{
                          width: "100%",
                          alignItems: "center",
                          alignSelf: "center",
          
                          marginBottom: 25,
                        }}
                      >
                        <Image
                          source={{
                            uri: localDatabase.selected_merchant.logo,
                          }}
                          style={{
                            height: 50,
                            width: 50,
                            borderRadius: 50,
                            backgroundColor: "black",
                            marginBottom: 15,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                        <Text style={{ color: "grey" }} small b font="12px">
                          Merchant
                        </Text>
                        <Text style={{ marginBottom: -10, textAlign: "center", fontFamily: "Roobert", color: "#002626" }} h4>
                          {localDatabase.selected_merchant.parent_name}
                        </Text>
                      </View>
                      <TouchableOpacity
                        style={{
                          backgroundColor: "#002626",
            width: "100%",
            paddingVertical: 8,
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: 5,
            flexDirection: "row",
            paddingHorizontal: 15,
                        }}
                      >
                      <Ionicons name="ios-checkmark-circle" size={24} color="#00ECAC" />
                        <Text style={{ color: "#00ECAC" }} small b>
                          Debt Linked
                        </Text>
                        <View />
                      </TouchableOpacity>
                    </Card>
                  </View>
                </View>`,
        },
      },
      {
        order: 5,
        type: 2,
        goalOrder: 1,
        stepNumber: 4,
        title: "Execute a payment",
        dbTitle: "payment",
        description: `Once all the building blocks are set up (entities and liabilities), executing the payments is a walk in the park! Payments are created to transfer funds from one account to another. For this demo we will simulate ${co_name} paying a user’s debt. The source will be ${co_name}’s corporate ACH account to the end-user's liability account.\nWhen the payment is created, Method will validate in real-time the payment with the lender. If the transaction is accepted, {b: Method will return the expected date the payment will be posted and the confirmation number from the lender}`,
        content: {
          type: 1,
          codeSnippet: `curl https://dev.methodfi.com/payments \n-X POST \n-H "Authorization: Bearer sk_SRewVmZa38kx9BWLCjLk3cVK" \n-H "Content-Type: application/json" \n-d '{
            "amount": "5000",
            "source": "acc_JMJZT6r7iHi8e",
            "destination": "acc_AXthnzpBnxxWP",
            "description": "Loan Pmt"
          }'
          `,
          requestFields: [
            {
              key: "amount",
              value: "5000",
              type: 2,
              desc: "Amount of payment in cents",
            },
            {
              key: "source",
              value: "acc_JMJZT6r7iHi8e",
              preloadKey: "preset.source_id",
              desc: "Company Account",
              type: 1,
            },
            {
              key: "destination",
              value: "acc_AXthnzpBnxxWP",
              preloadKey: "accounts.id",
              desc: "Debt ID",
              type: 1,
            },
            {
              key: "description",
              value: "Loan Pmt",
              type: 0,
              desc: "Optional description",
            },
          ],
          botDetails: [
            {
              title: "Pulling Funds",
            },
            {
              title: "Transmitting Payment",
            },
            {
              title: "Confirming with Merchant",
              preloadTitle:
                "`Confirming with ${localDatabase.selected_merchant.parent_name}`",
              sub: [
                {
                  key: "Status",
                  value: "Confirmed",
                },
                {
                  key: "Expected Post Data",
                  value: "3/15/22",
                  preloadValue:
                    "const today = new Date(); const postDate = new Date(); postDate.setDate(today.getDate() + 2); postDate.toLocaleDateString()",
                },
                {
                  key: "Confirmation ID",
                  value: "3dsfdj84",
                  preloadValue: "localDatabase.payment.id.substring(4)",
                },
              ],
            },
          ],
          successCode: `<View
          style={[
            styles.databaseView,
            { borderTopColor: "#002626", borderTopWidth: 3 },
          ]}
        >
          <View style={{ width: 300, justifyContent: "space-between" }}>
            <Card>
              <View
                style={{
                  width: "100%",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                <Image
                  source={{
                    uri: "https://assets.seedfi.com/assets/static/media/seedfi-logo-black.cb40ad97.svg",
                  }}
                  resizeMode="contain"
                  style={{
                    height: 50,
                    width: 50,
                    borderRadius: 80,
                    backgroundColor: "white",
                    marginBottom: 15,
                    alignItems: "center",
                    justifyContent: "center",
                    borderWidth: 0.5,
                    borderColor: "lightgrey",
                  }}
                />
                <Text style={{ color: "grey" }} small b font="12px">
                  Payment: {localDatabase.payment.id.substring(4)}
                </Text>
                <Text style={{ marginBottom: 5, textAlign: "center", fontFamily: "Roobert", color: "#002626" }} h4>
                  SeedFi
                </Text>
                <Text style={{ color: "grey", marginBottom: 10 }} small>
                  {localDatabase.payment.description}
                </Text>
              </View>
              <TouchableOpacity
                style={{
                  backgroundColor: "#002626",
                  width: "100%",
                  paddingVertical: 8,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: 5,
                  flexDirection: "row",
                  paddingHorizontal: 15,
                }}
              >
              <Ionicons name="ios-checkmark-circle" size={24} color="#00ECAC" />
                <Text style={{ color: "#00ECAC" }} small b>
                  SeedFi Sent {"$"}{localDatabase.payment.amount}
                </Text>
                <View />
              </TouchableOpacity>
            </Card>
        
            <View
              style={{
                alignSelf: "center",
                marginVertical: 10,
                width: 0,
                borderLeftWidth: 1,
                borderLeftColor: "grey",
                borderStyle: "dashed",
                height: 75,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  backgroundColor: "#002626",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingVertical: 6,
                  paddingHorizontal: 15,
                  alignSelf: "center",
                  borderRadius: 50,
                }}
              >
                <Text style={{ color: "white" }} small b>
                {"$"}{localDatabase.payment.amount}
                </Text>
              </View>
            </View>
        
            <Card
              style={{ width: "100%", alignSelf: "center", marginBottom: 25 }}
            >
              <View style={{ marginBottom: 25 }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    marginBottom: -10,
                  }}
                >
                  <View
                    style={{
                      height: 20,
                      width: 20,
                      borderRadius: 50,
                      backgroundColor: "black",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 10,
                      marginTop: 6,
                    }}
                  >
                    <Ionicons name="person" size={11} color="#00ECAC" />
                  </View>
                  <Text style={{ fontFamily: "Roobert" }} h5>{localDatabase.entities.individual.first_name + " " + localDatabase.entities.individual.last_name} Debt</Text>
                </View>
                <Text style={{ color: "grey" }} font="11px">
                  Entity ID: {localDatabase.entities.id}
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  alignItems: "center",
                  alignSelf: "center",
        
                  marginBottom: 25,
                }}
              >
                <Image
                  source={{
                    uri: localDatabase.selected_merchant.logo,
                  }}
                  style={{
                    height: 50,
                    width: 50,
                    borderRadius: 50,
                    backgroundColor: "black",
                    marginBottom: 15,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
                <Text style={{ color: "grey" }} small b font="12px">
                  Merchant
                </Text>
                <Text style={{ marginBottom: -10, textAlign: "center", fontFamily: "Roobert" }} h4>
                  {localDatabase.selected_merchant.parent_name}
                </Text>
              </View>
              <TouchableOpacity
                style={{
                  backgroundColor: "#002626",
            width: "100%",
            paddingVertical: 8,
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: 5,
            flexDirection: "row",
            paddingHorizontal: 15,
                }}
              >
              <Ionicons name="ios-checkmark-circle" size={24} color="#00ECAC" />
                <Text style={{ color: "#00ECAC" }} small b>
                  Merchant Received {"$"}{localDatabase.payment.amount}
                </Text>
                <View />
              </TouchableOpacity>
            </Card>
          </View>
        </View>`,
        },
      },
      {
        order: 6,
        type: 4,
        title: "Finish",
        description: `Maybe you want to let users upload their own multimedia to execute some action. As an example, `,
      },
    ], // FIXME: remove and set to null for prod
    selectedTab: {
      index: 0,
      type: "auto",
    },
    gradients: genGradients(7),
  }
);
